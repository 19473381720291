import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Router } from 'react-router-dom'
import Routes from './routes'
import history from './config/history'
import './config/i18n-config.ts'
import Modal from 'react-modal'
import { setupAuthCycle } from './infra/firebase/auth'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LoadScript } from '@react-google-maps/api'
import googleApiKey from './config/mapsApiKey'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

setupAuthCycle()

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    {' '}
    <div className="sk-spinner sk-spinner-pulse"> </div>
  </div>
)

Modal.setAppElement('#root')

// eslint-disable-next-line no-unused-vars
declare global { interface TouchEvent { scale: any; } }

if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
  window.document.addEventListener('touchmove', e => {
    if (e.scale !== 1) {
      e.preventDefault()
    }
  }, { passive: false })
}

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <LoadScript googleMapsApiKey={googleApiKey!}>
    <Router history={history}>
      <React.Suspense fallback={loading()}>
       <QueryClientProvider client={queryClient}>
        <Routes/>
        </QueryClientProvider>
      </React.Suspense>
      {' '}
      <ToastContainer autoClose={10000}/>
    </Router>
    </LoadScript>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
