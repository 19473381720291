import axios from 'axios'
import { getToken, logout } from '../firebase/auth'

let baseUrl = process.env.REACT_APP_URL_HOMOLOG

if (process.env.REACT_APP_PRODUCTION === 'true') {
  baseUrl = process.env.REACT_APP_URL_PRODUCTION
}

export async function post (url: string, object: any) : Promise<any> {
  try {
    const token = await getToken()
    if (token === '') return
    const response = await axios.post(baseUrl + url, object, {
      headers: { Authorization: `Bearer ${token}` }
    })
    if (response.status === 200 || response.status === 201) {
      return new Promise((resolve) => resolve(response.data))
    } if (response.status === 403) {
      await logout()
    } else {
      return new Promise((resolve, reject) => reject(response))
    }
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export async function put (url: string, object: any) : Promise<any> {
  try {
    const token = await getToken()
    if (token === '') return
    const response = await axios.put(baseUrl + url, object, {
      headers: { Authorization: `Bearer ${token}` }
    })
    if (response.status === 200 || response.status === 201) {
      return new Promise((resolve) => resolve(response.data))
    } if (response.status === 403) {
      await logout()
    } else {
      return new Promise((resolve, reject) => reject(response))
    }
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export async function get (url: string, abortController: AbortController = new AbortController()) : Promise<any> {
  const token = await getToken()
  if (token === '') return
  const response = await axios.get(baseUrl + url, {
    signal: abortController.signal,
    headers: { Authorization: `Bearer ${token}` }
  })
  if (response.status === 200 || response.status === 201) {
    return new Promise((resolve) => resolve(response.data))
  } if (response.status === 403) {
    await logout()
  } else {
    return new Promise((resolve, reject) => reject(response))
  }
}

export async function deleteObject (url: string) : Promise<any> {
  const token = await getToken()
  if (token === '') return
  const response = await axios.delete(baseUrl + url, { headers: { Authorization: `Bearer ${token}` } })
  if (response.status === 200 || response.status === 201) {
    return new Promise((resolve) => resolve(response.data))
  } if (response.status === 403) {
    logout()
  } else {
    return new Promise((resolve, reject) => reject(response))
  }
}
