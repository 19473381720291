import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationPTBR from './translations/translation-pt-br.json'
import translationES from './translations/translation-es.json'
import translationEN from './translations/translation-en.json'

// https://react.i18next.com/latest/trans-component for components with text put inbetween
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: translationEN
      },
      'pt-BR': {
        translation: translationPTBR
      },
      es: {
        translation: translationES
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })
