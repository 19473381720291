import history from '../../config/history'
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, onIdTokenChanged, signInWithEmailAndPassword, signOut, User } from 'firebase/auth'
import { app } from '.'
import { getCurrentCondominium, getEmployeeUser, setCurrentCondominium } from '../backend/employee-user'

const auth = getAuth(app)
let token : string | null| undefined = localStorage.getItem('token')

export async function loginWithEmail (email: string, password: string) : Promise<User> {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password)
    return response.user
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export async function signupWithEmail (email: string, password: string) : Promise<User> {
  try {
    const response = await createUserWithEmailAndPassword(auth, email, password)
    return response.user
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export async function logout () : Promise<undefined> {
  try {
    await signOut(auth)
    localStorage.removeItem('currentCondominium')
    localStorage.removeItem('token')
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export async function getToken (): Promise<String | null| undefined> {
  if (token === null || token === undefined || token === '') {
    token = localStorage.getItem('token')
  }
  return token
}

export async function updateCurrentCondominium () {
  const currCondominium = getCurrentCondominium()
  const user = await getEmployeeUser()
  if (user.employees.length === 0) return
  const firstEmployee = user.employees[0]
  if (currCondominium === firstEmployee.condominiumId) return
  setCurrentCondominium(firstEmployee.condominiumId)
  history.push('/')
}

export function setupAuthCycle () {
  onAuthStateChanged(auth, (user) => {
    if (!user) {
      // checks first if user is already in login page
      if (window.location.pathname !== '/login' && !window.location.pathname.includes('/signup/employee')) {
        history.push('/login', { path: window.location.pathname })
      }
    }
  })
  onIdTokenChanged(auth, async (user) => {
    if (user) {
      token = await user.getIdToken()
      localStorage.setItem('token', token!)
      await updateCurrentCondominium()
    }
  })
}
