import { get, post, put } from './base-rest-service'

interface EmployeeUserInviteCodeRequest {
  condominiumId?: number
  email?: string
  fullName?: string
  identityCode?: string
}

interface EmployeeRequest {
  accessType?: 'PENDING' | 'APPROVED'
  address?: string
  condominiumId?: number
  criminalRecordFiles?: (string | {
    file: string
    name: string
  })[]
  emergencyName?: string
  emergencyNumber?: string
  id?: number
  identityFiles?: (string | {
    file: string
    name: string
  })[]
  proofOfAddressFiles?: (string | {
    file: string
    name: string
  })[]
  telephoneNumber?: string
  userId?: number
}
interface UserRequest {
  birthDate?: string | null | ''
  cellphoneNumber?: string
  email?: string
  firstName?: string
  gender?: string
  id?: number
  identityCode?: string
  isComplete?: boolean
  lastName?: string
  photo: string
}
interface UserEmployeeRequest {
  employee: EmployeeRequest
  user: UserRequest
}

export async function getEmployeeUser () : Promise<any> {
  try {
    const response = await get('employee/login')
    return response
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export async function signup (request: UserEmployeeRequest) : Promise<any> {
  try {
    const response = await post('employee/signup', request)
    return response
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export async function createInvite (request: EmployeeUserInviteCodeRequest) : Promise<any> {
  try {
    const response = await post('employee/create-invite', request)
    return response
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export async function getInvite (code: string) : Promise<any> {
  try {
    const response = await get(`employee/invite-code/${code}`)
    return response
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export async function updateAccessType (employeeId: number, accessType: 'APPROVED' | 'DENIED') {
  try {
    const response = await put(`employee/access-type/${employeeId}`, { accessType })
    return response
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export function setCurrentCondominium (condominiumId: number) {
  localStorage.setItem('currentCondominium', String(condominiumId))
}

export function getCurrentCondominium () : number | null | undefined {
  const currentCondominium = localStorage.getItem('currentCondominium')
  if (currentCondominium) {
    const numberCurrentCondominium = Number(currentCondominium)
    if (numberCurrentCondominium !== undefined) {
      return numberCurrentCondominium
    }
  }
  return 0
}

export async function checkInIfEmployeeExist (idNumber: number) {
  try {
    const response = await post('visitors/employee-check-in', { idNumber, condominiumId: getCurrentCondominium() })
    return response
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export async function checkOutEmployee (userId: number) {
  try {
    const response = await post('visitors/check-out', { condominiumId: getCurrentCondominium(), userId })
    return response
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}
